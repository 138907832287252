<template>
	<v-container fluid>
		<div class="d-flex flex-wrap py-2">
			<v-btn class="ma-1" color="primary" small @click="getLiquidaciones">
				<v-icon left>mdi-reload</v-icon>Recargar
			</v-btn>
			<!-- <v-btn
				class="ma-1"
				color="primary"
				small
				@click.stop="facturaSinContrato"
				v-if="$root.acceso('CREAR_FACTURA_COLABORADORES')"
			>
				<v-icon left>mdi-plus</v-icon>Factura sin contratos
			</v-btn>
			<v-btn
				class="ma-1"
				color="primary"
				small
				@click="exportarAcumulativoAnual"
				:disabled="selected.length == 0 || !selected.every(f => f.estadoFactura == 'Pagada')"
			>
				<v-icon left>mdi-database-export-outline</v-icon>Exportar acumulativo anual
			</v-btn> -->
			<v-btn
				class="ma-1"
				color="primary"
				small
				to="/autofacturacion/comisiones"
				v-if="false"
			>
				<v-icon left>mdi-star-outline</v-icon>Ver comisiones especiales
			</v-btn>
		</div>
		<v-card>
			<v-data-table
				fixed-header
				:items="liquidaciones"
				:headers="liquidacionesHeader"
				show-select
				class="fixed-checkbox"
				item-key="idUsuario"
				v-model="selected"
				:single-select="false"
				:calculate-widths="true"
				:search="search"
				:loading="loading"
				:footer-props="{
					showFirstLastPage: true,
					showCurrentPage: true,
					itemsPerPageOptions: [10, 25, 50],
				}"
				checkbox-color="secondary"
			>
				<template v-slot:body.prepend="{ headers }">
					<table-filters :headers="headers" :items="liquidaciones" v-model="inlineFilters"></table-filters>
				</template>

				<template v-slot:item.canal="{ item }">
					<v-chip label outlined :color=" item.acumulativo >= 0 ? 'success' : 'error' " :class="{ 'v-chip--active' : true }">
            <v-icon left>
              mdi-account
            </v-icon>
            {{item.canal}}
          </v-chip>
				</template>

				<template v-slot:item.fechaLiquidacion="{ item }">
					<v-chip label outlined v-text="parseDate(item.fechaLiquidacion)"></v-chip>
				</template>

				<template v-slot:item.acumulativo="{ item }">
					<span
						:class="{'font-weight-bold' : true, 'error--text' : item.acumulativo < 0, 'text-body-2' : true }"
						v-text="`${Number(parseFloat(item.acumulativo).toFixed(2)) || 0} €`"
					/>
				</template>

				<template v-slot:item.nContratosSinLiquidar="{ item }">
					<span class="font-weight-bold" v-if="contratosSinLiquidar[String(item.idUsuario)] != null">{{contratosSinLiquidar[String(item.idUsuario)]}}</span>
					<v-progress-circular v-else indeterminate color="secondary" />
				</template>

				<template v-slot:item.acciones="{ item }">
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon v-on="on" v-bind="attrs" :to="`/liquidaciones/${item.idUsuario}`" color="secondary">
								<v-icon>mdi-format-list-bulleted</v-icon>
							</v-btn>
						</template>
						Ver detalles
					</v-tooltip>
				</template>
			</v-data-table>
		</v-card>

    <!-- <v-dialog
      :value="detailsId"
      @input="(v) => (v === false ? (detailsId = null) : null)"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <Liquidacion :key="detailsId" v-model="detailsId" />
    </v-dialog> -->

	</v-container>
</template>

<script>
import { getSituacionIcon, perColumnFilter, parseDate, downloadFile } from "@/utils/index.js";

export default {
	components: {
		TableFilters: () => import("@/components/TableFilters.vue"),
		StatusChip: () => import("@/components/StatusChip.vue"),
		Liquidacion: () => import("./Liquidacion.vue"),
	},
	props: ['codigoContrato'],
	data() {
		return {
			debounce: true,
			loading: true,
			page: 2,
			selected: [],
			search: null,
			detailsId: this.$route.query.idLiquidacion || null,
			pdfId: false,
			liquidaciones: [],
			contratosSinLiquidar: { },
			saldosCanal: [],
			saldosPendientesCanal: [],
			liquidacionesHeader: [
				{ text: "Canal", value: "canal" },
				{ text: "Fecha Última liquidación", dataType: "date", value: "fechaLiquidacion" },
				{ text: "Saldo", value: "acumulativo" },
				{ text: "Contratos sin liquidar", value: "nContratosSinLiquidar" },
				{ text: "Acciones", value: "acciones", sortable: false, filterable: false, },
			].map((header) => {
				return {
					class: "text-no-wrap sticky-header",
					cellClass: "pa-2 text-no-wrap",
					filter: (value) =>
						perColumnFilter(
							value,
							this.inlineFilters[header.value],
							header.dataType
						),
					dataType: "text",
					...header,
				};
			}),

			inlineFilters: {},
			comboboxes: {},
			loadingPDF: null,

			nuevaFactura: {
				show: false,
				resolve: null,
				reject: null,
				creating: null
			},
			colaboradores: [],
		};
	},
	computed: {
		totalSaldos() {
			 return parseFloat( this.saldosPendientesCanal.reduce( (t, c) => t + c.saldo , 0)).toFixed(2) * 1
		}
	},
	methods: {
		getSituacionIcon,
		parseDate,
		async getLiquidaciones() {
			this.contratosSinLiquidar = {};
			this.loading = true;
			const { data } = await axios({
				url: `${process.env.VUE_APP_OUR_API_URL}/liquidaciones/liquidaciones.php`,
				method: 'GET',
				params: { token: this.$store.getters.getJwtEmpresa }
			});

			this.liquidaciones = data;

			this.loading = false;

			const perChunk = 5;

			const chunks = this.liquidaciones.reduce((resultArray, item, index) => {
				const chunkIndex = Math.floor(index / perChunk)

				if (!resultArray[chunkIndex]) {
					resultArray[chunkIndex] = [] // start a new chunk
				}

				resultArray[chunkIndex].push(item)

				return resultArray
			}, [])

			for (const chunk of chunks) {
				await Promise.all(chunk.map(l => this.getContratos(l.idUsuario, l.canal) ) )
				await this.$nextTick();
			}

		},
		async getContratos(idUsuario, canal) {
      const { data } = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_OUR_API_URL}/liquidaciones/contratosSinLiquidar.php`,
        params: {
          canal,
          token: this.$store.getters.getJwtEmpresa,
					count: 1
        },
      })
			this.$set(this.contratosSinLiquidar, idUsuario, data);
      // this.contratosSinLiquidar[ idUsuario ] = data
    },
		async getColaboradores() {
			return new Promise((resolve, reject) => {
				axios({
					method: "GET",
					url: `${process.env.VUE_APP_OUR_API_URL}/colaboradores.php`,
					params: {
						token: this.$store.getters.getJwtEmpresa,
					},
				}).then((res) => {
					this.colaboradores = res.data.map((c) => {
						c.name = c.Nombre;
						c.Nombre = [...new Set([c.NombreContrato, c.Nombre])].join("__");
						c.esCanal = c.ancestor_id == null;
						c.esSubAgente = c.ancestor_id != null;
						c.facturable = [c.IBAN, c.porcentajeIva, c.porcentajeIrpf, c.direccion, c.cp, c.ciudad, c.provincia, c.cif].every(con => con != null);
						return c;
					}).filter(c => {
						// console.log({ fac: c.facturable, sub: c.esSubAgente, nombre: c.NombreContrato })
						return c.facturable
					}).sort((a, b) => (a.name) < b.name ? -1 : 1);
					resolve();
				});
			})
		},
		verPDF(archivo) {
			axios({
				method: 'GET',
				url: `${process.env.VUE_APP_OUR_API_URL}/autofacturar.php`,
				params: {
					token: this.$store.getters.getJwtEmpresa,
					archivo: archivo,
				},
				responseType: 'blob',
			}).then(res => {
				downloadFile(new File([res.data], archivo, { type: 'application/pdf' }), true);
			}).catch(err => {
				console.error(err);
				this.$root.$emit('snack', 'Ha ocurrido un error');
			})
		},
		exportarAcumulativoAnual() {
			let canal = [];
			if (this.selected.length > 0) {
				canal = [...new Set(this.selected.map(s => s.canal))]
			} else {
				canal = [...new Set(this.facturas.map(s => s.canal))]
			}
			axios({
				method: 'GET',
				url: `${process.env.VUE_APP_OUR_API_URL}/acumulativoAnual.php`,
				responseType: 'blob',
				params: {
					canal,
					token: this.$store.getters.getJwtEmpresa,
				}
			}).then(res => {
				const MESES = [
					"Enero",
					"Febrero",
					"Marzo",
					"Abril",
					"Mayo",
					"Junio",
					"Julio",
					"Agosto",
					"Septiembre",
					"Octubre",
					"Noviembre",
					"Diciembre",
				];
				let excel = new File(
					[res.data],
					`Acumulativo_comisiones_completo_${MESES[new Date().getMonth()]}_${new Date().getFullYear()}.xlsx`,
					{
						type: "application/excel",
					}
				);
				downloadFile(excel, false);
			})
		},
		async facturaSinContrato() {
			const auxPromise = () => {
				return new Promise(async (resolve, reject) => {
					this.nuevaFactura.show = true;
					this.nuevaFactura.resolve = resolve;
					this.nuevaFactura.reject = reject;
				})
			}

			try {
				const canal = await auxPromise();
				this.nuevaFactura.creating = canal;

				axios({
					method: 'POST',
					url: `${process.env.VUE_APP_OUR_API_URL}/autofacturar.php`,
					data: {
						canal: [canal],
						fecha: new Date().getTime(),
						token: this.$store.getters.getJwtEmpresa,
						sinLineas: true,
					}
				}).then(res => {
					this.$root.$emit('snack', 'Factura generada correctamente')
					this.nuevaFactura.creating = null;
					this.nuevaFactura.show = false;
					this.nuevaFactura.resolve = null;
					this.nuevaFactura.reject = null;

					this.getLiquidaciones();
				})

			} catch (e) {
				console.error(e);
				this.nuevaFactura.show = false;
				this.nuevaFactura.resolve = null;
				this.nuevaFactura.reject = null;
			}
		}
	},
	mounted() {
		this.getLiquidaciones();
		this.getColaboradores();
	},
};
</script>

<style scoped>
.v-data-table /deep/ [role="columnheader"] {
	/* background: #f1f1f1 !important; */
}
</style>
