var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex flex-wrap py-2"},[_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary","small":""},on:{"click":_vm.getLiquidaciones}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-reload")]),_vm._v("Recargar ")],1),(false)?_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary","small":"","to":"/autofacturacion/comisiones"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-star-outline")]),_vm._v("Ver comisiones especiales ")],1):_vm._e()],1),_c('v-card',[_c('v-data-table',{staticClass:"fixed-checkbox",attrs:{"fixed-header":"","items":_vm.liquidaciones,"headers":_vm.liquidacionesHeader,"show-select":"","item-key":"idUsuario","single-select":false,"calculate-widths":true,"search":_vm.search,"loading":_vm.loading,"footer-props":{
					showFirstLastPage: true,
					showCurrentPage: true,
					itemsPerPageOptions: [10, 25, 50],
				},"checkbox-color":"secondary"},scopedSlots:_vm._u([{key:"body.prepend",fn:function(ref){
				var headers = ref.headers;
return [_c('table-filters',{attrs:{"headers":headers,"items":_vm.liquidaciones},model:{value:(_vm.inlineFilters),callback:function ($$v) {_vm.inlineFilters=$$v},expression:"inlineFilters"}})]}},{key:"item.canal",fn:function(ref){
				var item = ref.item;
return [_c('v-chip',{class:{ 'v-chip--active' : true },attrs:{"label":"","outlined":"","color":item.acumulativo >= 0 ? 'success' : 'error'}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account ")]),_vm._v(" "+_vm._s(item.canal)+" ")],1)]}},{key:"item.fechaLiquidacion",fn:function(ref){
				var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","outlined":""},domProps:{"textContent":_vm._s(_vm.parseDate(item.fechaLiquidacion))}})]}},{key:"item.acumulativo",fn:function(ref){
				var item = ref.item;
return [_c('span',{class:{'font-weight-bold' : true, 'error--text' : item.acumulativo < 0, 'text-body-2' : true },domProps:{"textContent":_vm._s(((Number(parseFloat(item.acumulativo).toFixed(2)) || 0) + " €"))}})]}},{key:"item.nContratosSinLiquidar",fn:function(ref){
				var item = ref.item;
return [(_vm.contratosSinLiquidar[String(item.idUsuario)] != null)?_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.contratosSinLiquidar[String(item.idUsuario)]))]):_c('v-progress-circular',{attrs:{"indeterminate":"","color":"secondary"}})]}},{key:"item.acciones",fn:function(ref){
				var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
				var on = ref.on;
				var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":("/liquidaciones/" + (item.idUsuario)),"color":"secondary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-list-bulleted")])],1)]}}],null,true)},[_vm._v(" Ver detalles ")])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }